@use "src/utils.scss";
$base_color: #8b93aa;

.navbarWapper {
  height: 4rem;
  display: flex;
  justify-content: space-between;
  .containerWapper {
    display: flex;
    align-items: center;
    .titleArea {
      .title-image {
        margin: 0px 30px 0px 10px;
        width: 100%;
        max-width: 300px;
        cursor: pointer;
        img {
          width: 263px;
          height: 26px;
        }
      }
      .mobile {
        display: none;
      }
    }
    .me-auto {
      width: 100%;
      display: flex;
      justify-content: center;
      .icons {
        color: $base_color;
        width: 2px;
        font-size: 20px;
        @include utils.mid_position;
      }
      .nav-link {
        cursor: pointer;
        font-size: 18px;
        @include utils.mid_position;
        margin: 25px 30px;
        text-decoration: none;
        color: $base_color;
        opacity: 0.7;
      }
      .nav-link:hover {
        opacity: 1;
      }
      .dlc {
        margin: 25px 15px;
      }
    }
    .nav-menu,
    .nav-menu.active {
      display: none;
    }
  }
  .language {
    color: $base_color;
    @include utils.mid_position;
    font-size: 1.5rem;
    margin: 0rem 15px 0 0;
    #dropdown-basic {
      background-color: #151515;
      border-color: #151515;
      box-shadow: none;
      color: #8b93aa;
      opacity: 0.7;
    }
    .dropdown-menu.show {
      background-color: #232323;
    }
    .dropdown-item.active {
      background-color: #ffd024;
      color: #232323;
    }
    .dropdown-item {
      color: #8b93aa;
      &:hover {
        background-color: #ffd024;
        color: #232323;
      }
      &:focus {
        color: #8b93aa;
      }
    }
    .languague_section {
      margin: auto 0.9rem;
    }
    .language-icons {
      cursor: pointer;
    }
  }
}

@media only screen and (min-width: 1920px) {
  .navbarWapper {
    width: 1920px;
    margin: 0 auto;
  }
  .language {
    margin: 0 5rem;
  }
}

@media only screen and (max-width: 1200px) {
  .navbarWapper {
    .containerWapper {
      width: 100%;
      .titleArea {
        display: flex;
        width: 100%;
        z-index: 10;
        justify-content: space-between;
        background-color: #151515;
        .title-image {
          width: auto;
          max-width: 300px;
          margin: 1.5rem 1.8rem;
          img {
            width: 100%;
          }
        }
        .mobile {
          display: flex;
          overflow: hidden;
          justify-content: space-between;
          align-items: center;
          .menu-icon {
            font-size: 30px;
            color: $base_color;
            margin-right: 30px;
            transition: all 0.5s ease;
          }
        }
      }
      .me-auto {
        display: none;
      }
      .nav-menu-items {
        list-style-type: none;
        padding: 0;
      }
      .nav-menu {
        background-color: #151515;
        width: 100%;
        height: 10rem;
        display: flex;
        justify-content: center;
        position: fixed;
        top: -100%;
        transition: 850ms;
        li {
          a {
            color: $base_color;
            font-size: 18px;
            text-decoration: none;
          }
        }
      }
      .nav-menu.active {
        display: block;
        width: 100%;
        height: auto;
        top: 68px;
        transition: 350ms;
        .nav-menu-items {
          .nav-menu-link {
            margin: 0 auto;
            font-size: 18px;
            color: $base_color;
            hr {
              color: $base_color;
              height: 2px;
            }
          }
          .language_btn {
            @include utils.mid_position();
            flex-direction: row-reverse;
            &::before {
              display: inline-block;
              margin-left: 0.255em;
              vertical-align: 0.255em;
              content: "";
              border-top: 0.3em solid;
              border-right: 0.3em solid transparent;
              border-bottom: 0;
              border-left: 0.3em solid transparent;
              transform: rotate(0deg);
              &:focus {
                transform: rotate(180deg);
                background-color: white;
              }
            }
          }
          .collapse.show {
            color: $base_color;
            .active {
              background-color: #ffd024;
              color: #232323;
            }
          }
        }
      }
    }
    .language {
      display: none;
    }
  }
}
