@use "src/utils.scss";
$weight_block_one: 100px;
$weight_block_two: 100px;
@mixin block($width, $height,$way) {
    width: 100%;
    height: $height;
    background-repeat: no-repeat;
    background-position: top;
    position: relative;
    z-index: 1;
    background-size: $width $height;
    background-position: $way;
}
.four_block {
    width: 100%;
    display: flex;
    justify-content: center;
    background-image: url("./../pictures/object/block.png");
    background-repeat: repeat;
    @include utils.padding-bottom();
    .four_block_leftArea {
        width: 100%;
        background-position: right;
        .left_top {
            background-image: url("./../pictures/object/shop_left.png");
            @include block(370px,900px, right);
        }
        .left_bottom {
            background-image: url("./../pictures/object/shop_left_bottom.png");
            @include block(370px,2600px, right);
        }
        .none {
            display: none;
        }
    }
    .four_block_mainArea {
        z-index: 10;
        position: relative;
        .four_block_topArea {
            flex-direction: column;
            @include utils.mid_position;
            .four_block_topArea_picContianer {
                width: 1165px;
                margin-top: 55px;
            }
            .four_block_topArea_textArea {
                .four_block_topArea_title {
                    @include utils.mid_position();
                    @include utils.title-text();
                    .line_container_left {
                        @include utils.line_container_left($weight_block_one,3px, "/pictures/object/cross_acacac.png", "/pictures/object/line_acacac.png");
                    }
                    span {
                        @include utils.font-size(headline);
                        color: #656363;
                    }
                    .line_container_right {
                        @include utils.line_container_right($weight_block_one,3px, "/pictures/object/cross_acacac.png", "/pictures/object/line_acacac.png");
                    }
                }
                
                .four_block_topArea_text {
                    @include utils.font-size(small);
                    line-height: 1.5;
                    text-align: center;
                    span {
                        color: #827f8a;
                    }
                }
                .four_block_topArea_button {
                    @include utils.button(50px, auto);
                    width: 250px;
                    margin: 0 auto;
                    a {
                        .button {
                            margin: 20px auto 55px;
                        }
                    }
                }
            }
        }
        .four_block_midArea {
            margin-top: 110px;
            .four_block_midArea_rowArea {
                @include utils.mid_position;
                &:nth-child(2) {
                    margin:50px 0;
                } 
                .four_block_midArea_single {
                    &:nth-child(1) {
                        margin-right:135px;
                    } 
                    .four_block_midArea_single_picContainer {
                        width: 100%;
                        height: 325px;
                        .four_block_midArea_single_picContainer_CD {
                            @include utils.shop_picture("/pictures/pictures/CD.png");
                            background-size: 100%;
                        }
                        .four_block_midArea_single_picContainer_album {
                            @include utils.shop_picture("/pictures/pictures/album.png");
                            background-size: 100%;
                        }
                        .four_block_midArea_single_picContainer_key_ring {
                            @include utils.shop_picture("/pictures/pictures/key_ring.png");
                            background-size: 100%;
                        }
                        .four_block_midArea_single_picContainer_badge {
                            @include utils.shop_picture("/pictures/pictures/badge.png");
                            background-size: 100%;
                        }
                        .four_block_midArea_single_picContainer_postcard {
                            @include utils.shop_picture("/pictures/pictures/postcard.png");
                            background-size: 100%;
                        }
                        .four_block_midArea_single_picContainer_paper_model {
                            @include utils.shop_picture("/pictures/pictures/paper_model.png");
                            background-size: 100%;
                        }
                    }
                    .four_block_midArea_single_textConainter {
                        text-align: left;
                        width: 300px;
                        .four_block_midArea_single_textConainter_title {
                            @include utils.font-size(title);
                            @include utils.title-text();
                            color: #242424;
                        }
                        .four_block_midArea_single_textConainter_into {
                            width: 280px;
                            height: 100px;
                            @include utils.font-size(snmall);
                            line-height: 1.5;
                            color: #4c4d4d;
                        }
                        .height {
                            height: 100px;
                        }
                    }
                }
            }
        }
        .four_block_bottomArea {
            width: 1000px;
            margin: 110px auto 0;
            display: flex;
            flex-direction: column;
            border: 3px solid rgba(38, 38, 38, .2);
            .four_block_bottomArea_title {
                @include utils.mid_position();
                width: 99%;
                background-image: url("./../pictures/object/blocktitle_shop.png");
                background-repeat: no-repeat;
                background: rgba(38, 38, 38, .2);
                margin: 3px auto;
                span {
                    font-size: 36px;
                    font-weight: bold;
                    line-height: 0.67;
                    color: #1e1e1e;
                }
                .line_container_left {
                    height: 60px;
                    color: #7a7a7a;
                    @include utils.line_container_left($weight_block_two,3px, "/pictures/object/cross_7a7a7a.png", "/pictures/object/line_7a7a7a.png");
                }
                .line_container_right {
                    height: 60px;
                    color: #7a7a7a;
                    @include utils.line_container_right($weight_block_two,3px, "/pictures/object/cross_7a7a7a.png", "/pictures/object/line_7a7a7a.png");
                }
            }
            .four_block_bottomArea_des {
                width: 700px;
                margin: 40px auto;
                @include utils.font-size(small);
                color: #3a3b3c;
                text-align: left;
            }
            
        }
        .four_block_bottomArea_button {
            width: 250px;
            margin: 0 auto;
            @include utils.button(35px, auto);
            a {
                .button {
                    margin: 20px auto 55px;
                }
            }
        }
       
        .arrow {
            @include utils.arrow(#aaabb0);
            @include utils.mid_position();
            text-decoration: none;
            flex-direction: column;
        }
        .reverse {
            @include utils.reverse(#aaabb0);
        }
    }
    .four_block_rightArea {
        width: 100%;
        background-position: left;
        .right_top {
            background-image: url("./../pictures/object/shop_right.png");
            @include block(370px,900px, left);
        }
        .right_bottom {
            background-image: url("./../pictures/object/shop_right_bottom.png");
            @include block(370px,2600px, left);
        }
        .none {
            display: none;
        }
    }
}
@media only screen and (max-width: 1500px) {
    .four_block {
        .four_block_leftArea {
            display: none;
        }
        .four_block_rightArea {
            display: none;
        }
    }
}
@media only screen and (max-width: 1280px) {
  .four_block {
        .four_block_leftArea {
            display: none;
        }
        .four_block_mainArea {
            .four_block_topArea {
                .four_block_topArea_picContianer {
                    width: 100%;
                    max-width: 900px;
                    margin-top: 75px;
                }
                .four_block_topArea_textArea {
                    margin-top: 0;
                    .four_block_topArea_title {
                        @include utils.title-text();
                        .line_container_left {
                            display: none;
                            .line {
                            }
                            .cross {
                                @include utils.cross-mobile();
                            }
                           
                        }
                        span {
                            display: block;
                            @include utils.font-size-mobile(headline);
                        }
                        .line_container_right {
                            display: none;
                            .line {
                            }
                            .cross {
                                @include utils.cross-mobile();
                            }
                           
                        }
                    }
                    .four_block_topArea_text {
                        width: 225px;
                        margin: 0px auto 0;
                        @include utils.font-size-mobile(small);
                    }
                    .four_block_topArea_button {
                        a {
                            .button {
                                margin: 30px auto 55px;
                                font-size: 18px;
                            }
                        }
                    }
                }
            }
            .four_block_midArea {
                margin-top: 110px;
                .four_block_midArea_rowArea {
                    flex-direction: column;
                    &:nth-child(3) {
                        .four_block_midArea_single {
                            &:nth-child(2) {
                                margin-bottom: 55px;
                            }
                        }
                    }
                    .four_block_midArea_single {
                        margin-bottom: 60px;
                        &:nth-child(1) {
                            margin-right: 0;
                        }
                        .four_block_midArea_single_picContainer {
                        }
                        .four_block_midArea_single_textConainter {
                            .four_block_midArea_single_textConainter_title {
                                @include utils.font-size-mobile(title);
                            }
                            .four_block_midArea_single_textConainter_into {
                                @include utils.font-size-mobile(small);
                            }
                        }
                        .four_block_midArea_single_textConainter {
                            .four_block_midArea_single_textConainter_into {
                                width: 100%;
                                height: 100px;
                            }
                        }
                    }
                    
                }
            }
            .four_block_bottomArea {
                background-size: 100% 600px;
                width: 90%;
                margin: 0 auto;
                .four_block_bottomArea_title {
                    .line_container_left {
                        display: none;
                        .line {
                        }
                        .cross {
                            @include utils.cross-mobile();
                            
                        }
                    } 
                        span {
                            @include utils.font-size-mobile(headline);
                            height: 60px;
                            @include utils.mid_position();
                        }
                    .line_container_right {
                        display: none;
                        .line {
                        }
                        .cross {
                            @include utils.cross-mobile();
                        }
                    } 
                    
                }
                .four_block_bottomArea_des {
                    width: 100%;
                    line-height: 1.8;
                    height: auto;
                    max-width: 700px;
                    @include utils.font-size-mobile(small);
                    padding: 0 20px;
                }
               
            }
            .four_block_bottomArea_button {
                a {
                    .button {
                        font-size: 18px;
                        margin: 30px auto 55px;
                    }
                }
            }
        }
        .four_block_rightArea {
            display: none;
        }
  }
}
