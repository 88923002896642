@use "./../utils.scss";
@mixin wrapper {
  position: absolute;
  width: 100%;
  height: 2000px;
  background-color: black;
  z-index: 999;
}
.secret_wrapper {
  @include wrapper();
  .block {
    height: 200px;
  }
  .login_area {
    color: white;
    .login_area_btn {
      background-color: white;
      margin-top: 20px;
      color: black;
      border-radius: 5px;
    }
    .FindMe {
      @include utils.mid_position();
    }
  }
  hr {
    width: 90%;
    max-width: 1000px;
    height: 3px;
    color: white;
    margin: 15px auto;
  }
  .error {
    color: red;
    font-size: 50px;
  }
  .pagination {
    @include utils.mid_position();
    margin: 20px auto 0;
    .page-item {
      .page-link {
        background-color: black;
        border-color: white;
        color: white;
      } 
    }
    .page-item.active {
      .page-link {
        background-color: white;
        color: black;
      }
    }
  }
  .data_area{
    color: white;
    width: 700px;
    height: 550px;
    margin: 50px auto;
    border: 3px solid white;
    padding: 20px 0;
    .data_area_single {
      display: none;
      div {
        margin-bottom: 20px;
        font-size: 28px;
      }
      p {
        text-align: left;
        margin: 0 auto;
        overflow-x: hidden;
        overflow-y: auto;
        font-size: 18px;
        word-break: keep-all;
        padding: 10px 150px 0;
      }
    }
    .active {
      display: block;
      position: relative;
      .small_text {
        position: absolute;
        color: black;
        opacity: 0.5;
        top: 100px;
        left: 250px;
      }
      .jqoije {
        height: 450px;
        overflow: scroll;
        overflow-x: hidden;
      }
    }
  }
}
.secret_wrapper_Behind {
  @include wrapper();
  .picture_Area {
    padding: 10% 0;
    .picture_Area_row {
      @include utils.mid_position();
      .picture_Singe {
        img {
          width: 400px;
        }
      }
    }
  }
}
.eye {
  margin: 70px auto;
  white-space: break-spaces;
  max-width: 800px;
  font-family: monospace;
}
.secret_wrapper_Exitst {
  @include wrapper();
  .login_area {
    max-width: 1000px;
    margin: 100px auto;
    background-color: white;
  }
}