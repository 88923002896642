@import url(https://fonts.googleapis.com/earlyaccess/notosanstc.css);
* {
    font-family: 'Noto Sans TC';
}
body {
  margin-top: 4rem;
  overflow-x: hidden;
}
.App {
  text-align: center;
  background-color: #151515;
}
.App-header {
  width: 100%;
  height: 4rem;
  background-color: #151515;;
  position: fixed;
  top: 0;
  display: block;
  transition: top 0.3s;;
  z-index: 999;
}
main img{
  max-width: 100%;
}

