.imageContainer {
    img {
        border: 3px solid #5b5b5b;
        width: auto;
    }
    iframe {
        width: 100%;
        height: 100%;
        border: 3px solid #5b5b5b;
    }
}

@media only screen and (max-width: 1280px) {
    .imageContainer {
        img {
            width: 100%;
        }
    }
}
