@use "src/utils.scss";
$weight_block_one: 170px;
.third_block {
    width: 100%;
    background-image: url("./../pictures/background/game_background.png");
    background-position: top;
    .third_block_main {
        width: 100%;
        background-image: url("./../pictures/background/third_block.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 4000px 100%;
        @include utils.padding-bottom();
        .third_block_main_title_area {
            .third_block_main_title {
                padding-top: 110px;
                @include utils.mid_position;
                .line_container_left {
                    @include utils.line_container_left($weight_block_one,3px, "/pictures/object/cross_7a7a7a.png", "/pictures/object/line_7a7a7a.png");;
                }
                span {
                    @include utils.font-size(headline);
                    color: #b6b1c0;
                    font-weight: 900;
                }
                .line_container_right {
                    @include utils.line_container_right($weight_block_one,3px, "/pictures/object/cross_7a7a7a.png", "/pictures/object/line_7a7a7a.png");;
                }
            }
            .game_into_area {
                text-align: center;
                .game_into_title {
                    @include utils.font-size(title);
                    @include utils.title-text();
                    text-align: center;
                    color: #a19daa;
                }
                .game_into_text {
                    width: 725px;
                    @include utils.font-size(small);
                    text-align: center;
                    color: #736f7c;
                    @include utils.title-arrow();
                    margin: 0px auto;
                }
                .game_into_text_mobile {
                    display: none;
                    color: #736f7c;
                }
            }
        }
        .game_wrapper {
            display: flex;
            justify-content: center;
            flex-direction: column;
            max-width: 1165px;
            margin: 40px auto 0px;
            .game_container {
                width: 1050px;
                height: 595px;
                border: solid 5px #5b5b5b;
                background-color: #373339;
                margin: 0 auto;
            }
            .game_into_area {
                width: 1035px;
                margin: 0 auto;
                max-width: 1035px;
                .game_into_toparea_block {
                    text-align: left;
                    margin-bottom: 55px;
                    .game_into_toparea_block_title {
                        @include utils.font-size(title);
                        padding: 5px 0;
                        color: #a19daa;
                    }
                    .game_into_toparea_block_des {
                        @include utils.font-size(small);
                        text-align: left;
                        color: #736f7c;
                        span {
                            border: 2px solid #373339;
                            background-color: rgb(243, 243, 243);;
                            border-radius: 5px;
                            padding: 1px 10px;
                            margin: 0 5px;
                            color: #373339;
                            font-size: 20px;
                            text-align: center;
                        }
                        .space_area {
                            margin-top: 15px
                        }
                    }
                }
                .arrow_area {
                    max-width: 1065px;
                    margin: 0 auto;
                }
            }
        }
        .third_block_button {
            width: 250px;
            margin: 0 auto;
            @include utils.button(55px,auto);
        }
    }
    .game_into_wrapper {
        width: 1000px;
        margin: 0px auto;
        padding-bottom: 110px;
        .game_into_bottomarea {
            .game_into_bottomarea_block {
                text-align: left;
                margin-bottom: 55px;
                .game_into_bottomarea_block_title {
                    @include utils.font-size(title);
                    padding: 5px 0;
                    color: #a19daa;
                }
                .game_into_bottomarea_block_des {
                    @include utils.font-size(small);
                    text-align: left;
                    color: #736f7c;
                    span {
                        border: 2px solid #373339;
                        background-color: rgb(243, 243, 243);;
                        border-radius: 5px;
                        padding: 1px 10px;
                        margin: 0 5px;
                        color: #373339;
                        font-size: 20px;
                        text-align: center;
                    }
                    .space_area {
                        margin-top: 15px
                    }
                }
            }
        }
    }
    .arrow {
        @include utils.arrow(#56545c);
        @include utils.mid_position();
        text-decoration: none;
        flex-direction: column;
    }
    .reverse {
        @include utils.reverse(#56545c);
    }
}

@media only screen and (max-width: 1280px) {
    .third_block {
        background-position: center;
        .third_block_main {
            background-size: 100% 100%;
            height: auto;
            .third_block_main_title_area {
                .third_block_main_title {
                    padding-top: 75px;
                    .line_container_left {
                        .line {
                            display: none
                        }
                        .cross {
                            @include utils.cross-mobile();
                        }                        
                    }
                    span {
                        @include utils.font-size-mobile(headline);
                    }
                    .line_container_right {
                        .line {
                            display: none
                        }
                        .cross {
                            @include utils.cross-mobile();
                        }  
                    }
                }
                .game_into_area {
                    width: 100%;
                    .game_into_title {
                        margin-top: 0;
                        @include utils.title-text();
                        @include utils.font-size-mobile(title);
                    }
                    .game_into_text {
                        margin: 10px auto 0;
                        @include utils.font-size-mobile(small);
                        width: 300px;
                        display: none;
                    }
                    .game_into_text_mobile {
                        display: block;
                        @include utils.font-size-mobile(small);
                        @include utils.title-arrow();
                        padding: 0 20px;
                    }
                }
            } 
            .game_wrapper {
                margin: 0px auto;
                .game_container {
                    display: none;
                }
                .game_into_area {
                    width: 100%;
                    .game_into_toparea_block {
                        display: none;
                    }
                }
            }   
        }
        .game_into_wrapper {
            width: 100%;
            @include utils.padding-bottom-mobile();
            .game_into_bottomarea {
                width: 335px;
                margin: 0 auto;
                @include utils.title-arrow();
                .game_into_bottomarea_block {
                    text-align: left;
                    margin-bottom: 40px;
                    &:nth-child(4) {
                        margin-bottom: 0px;
                    }
                    .game_into_bottomarea_block_title {
                        @include utils.font-size-mobile(title);
                    }
                    .game_into_bottomarea_block_des {
                        @include utils.font-size-mobile(small);
                    }
                }
            }
        }
    }
}