@use "src/utils.scss";
.game-area {
  position: relative;
  margin: 50px auto 10px auto;
  width: 600px;
  height: 400px;
  background-color: #373339;
}

.game-over {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 9;
}

.game-over::before {
  content: 'GAME OVER';
  position: absolute;
  width: 100%;
  height: 100%;
  color: #fff;
  font-size: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation-name: example;
  animation-duration: 1.5s;
  opacity: 0;
}

@keyframes example {
  0% {
    opacity: 0;
    font-size: 10px;
  }

  95% {
    opacity: 1;
    font-size: 75px;
  }
  100% {
    opacity: 0;
  }
}

.snake-dot {
  position: absolute;
  width: 2%;
  height: 3%;
  background-color: transparent;
  color: black;
  background-image: url("./../pictures/object/gamer.png");
  background-size: 100%;
  background-position: center;
  border: 1px solid transparent;
  z-index: 999;
}

.snake-food {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: grey;
  border: 1px solid #ffffff;
  z-index: 1;
}

.button_area {
  text-align: center;
  position: relative;
  @include utils.mid_position();
  justify-content: space-around;
  align-items: end;
  .start_button {
    @include utils.snakeGame_button();
  }
}

button:hover {
  background-color: #ffd024;
  transition: 0.5s all;
}
.dont_touch {
  color: grey;
  pointer-events: none;
}
.keyboard_area {
  width: 200px;
  .row_one {
    margin: 0 auto;
  }
  .row_two {
    @include utils.mid_position();
  }
}
.key {
  &:hover {
    border: 1px solid #eeeeee; 
  }
  cursor: pointer;
  width: 50px;
  height: 50px;
  background-color: rgb(243, 243, 243);
  border: 2px solid black;
  border-radius: 5px;
  grid-column: span 2;
  font-size: 20px;
  text-align: center;
  padding-top: 10px;
}
.space {
  width: 200px;
}