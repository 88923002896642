@use "src/utils.scss";
.five_block {
    background: {
        image: url("./../pictures/background/OST_background.png");
        repeat: no-repeat;
        size: 100% 2300px;
        position: center;
    }
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 110px;
    .five_block_leftArea {
        background-image: url("./../pictures/object/arrow.png");
        width: 90px;
        height: 135px;
        transform: rotate(0.5turn);
        position: relative;
        top: 160px;
    }
    .five_block_mainArea {
        text-align: left;
        margin: 75px 115px 0;
        .five_block_videoArea_wrapper {
            @include utils.mid_position();
            .five_block_videoArea {
                margin-right: 40px;
                padding-bottom: 25px;
                img {
                }
            }
            .five_block_videoArea_title {
                @include utils.font-size(headline);
                color:#c0bcb1;
                padding: 5px 0;
            }
            .five_block_videoArea_into {
                width: 480px;
                @include utils.font-size(small);
                text-align: left;
                color: #807c72;
            }
            .five_block_videoArea_button {
                @include utils.mid_position();
                @include utils.button(45px,auto);
            }
        }
    }
    .five_block_rightArea {
        background-image: url("./../pictures/object/arrow.png");
        width: 90px;
        height: 135px;
        position: relative;
        top: 160px;
    }
}
@media only screen and (max-width: 1280px) {
  .five_block {
    height: auto;
    background-size: 1300px 100%;
    .five_block_leftArea {
        display: none;
    }
    .five_block_mainArea {
        margin: 75px auto 0;
        .five_block_videoArea_wrapper {
            flex-direction: column;
            .five_block_videoArea {
                width: 20rem;
                margin: 0 auto;
                padding-bottom: 0;
            }
            .five_block_videoArea_title {
                @include utils.font-size-mobile(headline);
                width: 320px
            }
            .five_block_videoArea_into {
                @include utils.font-size-mobile(small);
                width: 310px;
                height: auto;
                margin: 0 auto;
            }
            .five_block_videoArea_button {
                a {
                    .button {
                        font-size: 18px;
                        margin: 30px auto 0;
                    }
                }
            }
        }
    }
    .five_block_rightArea {
        display: none;
    }
  }
}