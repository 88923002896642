@use "src/utils.scss";
.footerWrapper {
  background-color: #151515;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left_side {
    display: flex;
    .crespirit_icons {
      margin: 60px 0 40px 60px;
      img {
        width: 82px;
        height: 74px;
      }
    }
    .x_icons {
      width: 28px;
      margin: 84px 30px 63px 30px;
    }
    .nostuck_icons {
      width: 71px;
      margin: 65px 0 44px 0;
      img {
        width: 100%;
      }
    }
  }
  .right_side {
    @include utils.mid_position();
    color: #676767;
    margin: 30px 2rem 0 0;
    .top_area {
      @include utils.mid_position();
      margin-bottom: 10px;
      .pages_section ul {
        display: flex;
        list-style-type: none;
        margin: 0;
        padding: 0;
        li {
          font-size: 18px;
          margin: auto 1.25rem;
          font-weight: bold;
          cursor: pointer;
          opacity: 0.8;
          &:hover {
            opacity: 1;
          }
        }
      }
      .contactus_lineArea {
        display: none;
      }
      .social_icons {
        font-size: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        a {
          @include utils.mid_position();
          color: #676767;
          height: 35px;
        }
        svg {
          margin: auto 1rem;
          cursor: pointer;
          opacity: 0.7;
        }
        svg:hover {
          opacity: 1;
        }
        img {
          height: 35px;
          margin: 1rem 1rem;
          cursor: pointer;
          opacity: 0.7;
        }
        img:hover {
          opacity: 1;
        }
      }
    }
    .bot_area {
      display: flex;
      float: right;
      margin-right: 1rem;
      .copyright {
        font-size: 18px;
      }
    }
    .grading {
      background-image: url("../pictures/pictures/grading.jpg");
      width: 65px;
      height: 65px;
      background-size: 100%;
      background-repeat: no-repeat;
      margin: auto 0 0 1rem;
    }
  }
}
@media only screen and (min-width: 1920px) {
  .footerWrapper {
    width: 1920px;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 1280px) {
  .footerWrapper {
    flex-direction: column;
    .left_side {
      margin-top: 1.8rem;
      .crespirit_icons {
        margin: 0;
      }
      .x_icons {
        margin: auto 2rem;
        width: 1.8rem;
      }
      .nostuck_icons {
        padding-top: 5px;
        margin: 0;
        width: 4.5rem;
      }
    }
    .right_side {
      margin: auto;
      flex-direction: column;
      .top_area {
        flex-direction: column;
        .pages_section {
          display: none;
        }
        .contactus_lineArea {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 25px auto;
          .contact {
            margin: 0 1rem;
          }
        }
        .social_icons {
          font-size: 1.5rem;
          svg {
            font-size: 2rem;
          }
        }
      }
      .bot_area {
        margin-bottom: 1.5rem;
        margin-right: 0;
        .copyright {
          font-size: 12px;
        }
      }
      .right_area {
        .grading {
          width: 40px;
          height: 40px;
          margin: 0 0 1rem 1rem;
        }
      }
    }
  }
}
