@use "src/utils.scss";

@mixin button($size) {
  cursor: pointer;

  .button {
    position: relative;
    z-index: 10;
    background-image: url("./../pictures/object/button.png");
    width: 250px;
    height: 50px;
    font-size: $size;
    font-weight: bold;
    background-repeat: no-repeat;
    @include utils.mid_position;
    margin: 28px auto;
  }
}

.first_block {
  background-image: url("./../pictures/background/main_banner.png");
  background-position: top;
  width: 100%;
  height: 590px;

  .top_area {
    display: flex;
    justify-content: center;
    width: 1200px;
    height: 650px;
    margin: 0 auto;

    .fair_container_mobile {
      display: none;
    }

    .fair_container {
      width: 660px;
      height: 1120px;
      margin-top: 120px;
      position: relative;
      left: 80px;

      a {
        color: transparent;
        padding: 5px;
        position: relative;
        z-index: 999;
        top: 160px;
      }

      img {
        right: 0;
        z-index: 2;
        position: absolute;
        width: 660px;
      }
    }

    .logo_warpper {
      width: 720px;
      height: 270px;
      margin-top: 210px;
      position: relative;
      right: 75px;

      .logo {
        height: 230px;
        position: relative;

        .time_logo {
          position: absolute;
          right: 80px;
        }
      }

      .logo_mobile_wapper {
        display: none;
      }

      .text_mobile_container {
        display: none;
      }

      .button_area {
        width: 250px;
        margin: 0 auto;

        a {
          text-decoration: none;
          color: black;
          @include button(20px);

          .button {
            margin: 0 auto;
          }
        }
      }
    }
  }

  .effect {
    position: absolute;
    height: 100px;
    width: 100%;
    z-index: 11;
    top: 540px;
    background: rgb(26, 25, 26);
    background: linear-gradient(0deg,
        rgba(26, 25, 26, 0) 0%,
        rgba(26, 25, 26, 1) 50%,
        rgba(26, 25, 26, 0) 100%);
  }
}

@media only screen and (max-width: 1280px) {
  .first_block {
    width: 100%;
    height: 520px;

    .top_area {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: center;

      .fair_container {
        display: none;
      }

      .fair_container_mobile {
        display: block;
        position: absolute;
        max-width: 420px;
        height: 715px;
        margin-top: 85px;
      }

      .logo_warpper {
        width: 380px;
        height: 140px;
        margin-top: 335px;
        right: 0;
        z-index: 12;

        .logo {
          display: none;
        }

        img {
          display: none;
        }

        .logo_mobile_wapper {
          display: block;
          max-width: 380px;
          height: 140px;
          margin-top: 55px;
          position: relative;
          z-index: 13;

          img {
            display: block;
          }

          .time_logo_mobile {
            position: absolute;
            right: 50px;
            top: 0px;
            height: 25px;
            max-width: 150px;
            width: 100%;
          }
        }

        .button_area {
          a {
            position: relative;

            .button {
              font-size: 18px;
              z-index: 11;
            }
          }
        }
      }
    }

    .effect {
      top: 470px;
      z-index: 11;
    }
  }
}