@use "src/utils.scss";
$weight_block_one: 0px;
$weight_block_two: 275px;
$title_color: #afaab8;
$context_color: #625f69;
@mixin Switch_imgSize {
  margin: 14px 0 0;
  background-size: 100%;
  max-width: 500px;
  width: 100%;
  height: 168px;
}
@mixin Hover($color) {
  &:hover {
    color: #ffd024;
  }
  color: $color;
  text-decoration: none;
}

.twice_block_one {
  background-image: url("./../pictures/background/black_block.png");
  background-repeat: repeat;
  width: 100%;
  z-index: 10;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  @include utils.padding-bottom();
  .Twice_block_title_Area {
    .Twice_block_title {
      width: 1050px;
      @include utils.block_title();
      span {
        font-size: 34px;
        font-weight: 900;
        color: $title_color;
      }
      .line_container_left {
        @include utils.line_container_left(
          $weight_block_one,
          3px,
          "/pictures/object/cross_56545C.png",
          "/pictures/object/line_56545C.png"
        );
        .cross {
          display: none;
        }
      }
      .line_container_right {
        @include utils.line_container_right(
          $weight_block_one,
          3px,
          "/pictures/object/cross_56545C.png",
          "/pictures/object/line_56545C.png"
        );
        .cross {
          display: none;
        }
      }
    }
    .vidoe_container {
      max-width: 1050px;
      margin: 0px auto;
    }
  }
  .Video_textArea {
    width: 1050px;
    color: #acacac;
    text-align: left;
    @include utils.title-arrow();
    .Video_textArea_title {
      @include utils.font-size(title);
      color: #b6b1c0;
      @include utils.title-text();
    }
    span {
      @include utils.font-size(small);
      color: $context_color;
    }
    a {
      @include Hover($title_color);
    }
  }
  .info_wrapper {
    margin: 0px auto 165px;
    .Twice_block_into {
      width: 1050px;
      margin: 0px auto 40px;
      padding-top: 110px;
      @include utils.mid_position;
      span {
        @include utils.font-size(headline);
        color: $title_color;
      }
      .line_container_left {
        @include utils.line_container_left(
          $weight_block_two,
          3px,
          "/pictures/object/cross_56545C.png",
          "/pictures/object/line_56545C.png"
        );
      }
      .line_container_right {
        @include utils.line_container_right(
          $weight_block_two,
          3px,
          "/pictures/object/cross_56545C.png",
          "/pictures/object/line_56545C.png"
        );
      }
    }
    .info_picture_Container {
      display: inline-block;
      overflow: hidden;
      img {
        max-width: 1050px;
        width: 100%;
        //transition-duration: 1s;
        //transition-property: transform;
        &:hover {
          //transform: scale(1.05);
        }
      }
    }
    table {
      margin: 0 auto;
      width: 100%;
      max-width: 1050px;
      tbody {
        tr {
          text-align: left;
          td {
            font-size: 18px;
            text-align: left;
            height: 40px;
            color: $context_color;
            a {
              @include Hover($context_color);
            }
          }
        }
      }
    }
  }
  .platform_wrapper {
    margin: 110px auto 0;
    max-width: 1210px;
    width: 100%;
    .Twice_block_into {
      @include utils.mid_position();
      span {
        @include utils.font-size(headline);
        color: #ffd024;
      }
      .line_container_left {
        @include utils.line_container_left(
          $weight_block_two,
          3px,
          "/pictures/object/cross_yellow.png",
          "/pictures/object/line_yellow.png"
        );
      }
      .line_container_right {
        @include utils.line_container_right(
          $weight_block_two,
          3px,
          "/pictures/object/cross_yellow.png",
          "/pictures/object/line_yellow.png"
        );
      }
    }
    .platform_container {
      @include utils.mid_position();
      position: relative;
      .platform_leftArea {
        background-image: url("./../pictures/object/arrow.png");
        max-width: 90px;
        width: 100%;
        height: 135px;
        transform: rotate(0.5turn);
        position: absolute;
        left: -100px;
      }
      .platform_mainArea {
        @include utils.mid_position();
        width: 100%;
        max-width: 1010px;
        .platform_mainArea_left {
          width: 100%;
          max-width: 500px;
          margin: 40px 0 0;
        }
        .platform_mainArea_main {
          width: 50px;
        }
        .platform_mainArea_right {
          width: 100%;
          max-width: 500px;
          .switch_img {
            @include Switch_imgSize();
            background-image: url("./../pictures/pictures/switch_ch.png");
          }
          .switch_img_en {
            @include Switch_imgSize();
            background-image: url("./../pictures/pictures/switch.png");
          }
        }
      }
      .platform_rightArea {
        background-image: url("./../pictures/object/arrow.png");
        max-width: 90px;
        width: 100%;
        height: 135px;
        position: absolute;
        right: -100px;
      }
    }
  }
  .arrow_area {
    width: 100%;
    max-width: 1065px;
    .arrow {
      text-decoration: none;
      @include utils.arrow(#56545c);
    }
    .reverse {
      @include utils.reverse(#56545c);
    }
  }
  .twice_block_two {
    background-image: url("./../pictures/background/black_block.png");
    background-repeat: repeat;
    width: 100%;
    .Twice_block_into {
      width: 1050px;
      margin: 0px auto 40px;
      padding-top: 110px;
      @include utils.mid_position;
      span {
        @include utils.font-size(headline);
        color: $title_color;
      }
      .line_container_left {
        @include utils.line_container_left(
          $weight_block_two,
          3px,
          "/pictures/object/cross_56545C.png",
          "/pictures/object/line_56545C.png"
        );
      }
      .line_container_right {
        @include utils.line_container_right(
          $weight_block_two,
          3px,
          "/pictures/object/cross_56545C.png",
          "/pictures/object/line_56545C.png"
        );
      }
    }
    .vidoe_container {
      max-width: 1050px;
      margin: 0px auto;
    }
    .Video_textArea_two {
      width: 1050px;
      height: 145px;
      font-size: 18px;
      margin: 0px auto;
      text-align: left;
      .Video_textArea_title {
        @include utils.font-size(title);
        color: #a19daa;
        @include utils.title-text();
      }
      span {
        @include utils.font-size(small);
        color: $context_color;
      }
    }
    .Twice_block_into_small_Area {
      margin-top: 110px;
      .Twice_block_into_small {
        display: flex;
        justify-content: center;
        &:nth-child(5) {
          margin-bottom: 55px;
        }
        .Twice_block_into_small_left {
          width: 345px;
          height: auto;
          border: solid 3px #5b5b5b;
          background-color: #373339;
          margin-right: 40px;
        }
        .Twice_block_into_small_right {
          text-align: left;
          .Twice_block_into_small_right_title {
            @include utils.font-size(title);
            color: #a19daa;
            @include utils.title-text();
          }
          .Twice_block_into_small_right_text {
            width: 650px;
            height: 75px;
            @include utils.font-size(small);
            color: $context_color;
          }
        }
      }
      hr {
        color: $context_color;
        width: 80%;
        height: 3px;
        margin: 30px auto;
        max-width: 1100px;
      }
    }
    .imageContainer {
      img {
        max-width: 1055px;
      }
    }
    .hide_arrow {
      width: 100px;
      margin: 0px auto 0px;
      .arrow {
        @include utils.arrow(#56545c);
        @include utils.mid_position();
        flex-direction: column;
        text-decoration: none;
      }
      .reverse {
        @include utils.reverse(#56545c);
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  .twice_block_one {
    .Twice_block_title_Area {
      padding: 0 15px;
      width: 100%;
      max-width: 650px;
      .Twice_block_title {
        width: 100%;
        margin-top: 140px;
        .line_container_left {
          display: none;
        }
        span {
          font-size: 20px;
        }
        .line_container_right {
          display: none;
        }
      }
    }
    .vidoe_container {
      width: 100%;
      max-width: 650px;
    }
    .Video_textArea {
      width: 100%;
      height: auto;
      @include utils.title-arrow();
      max-width: 650px;
      .Video_textArea_title {
        @include utils.font-size-mobile(title);
      }
      span {
        @include utils.font-size-mobile(small);
      }
    }
    .info_wrapper {
      width: 100%;
      padding: 0px 15px;
      max-width: 650px;
      .Twice_block_into {
        width: 100%;
        margin: 0px auto 40px;
        .line_container_left {
          .line {
            display: none;
          }
          .cross {
            @include utils.cross-mobile();
          }
        }
        span {
          @include utils.font-size-mobile(headline);
          margin: 0 15px;
        }
        .line_container_right {
          .line {
            display: none;
          }
          .cross {
            @include utils.cross-mobile();
          }
        }
      }
      table {
        tbody {
          tr {
            td {
            }
          }
        }
      }
    }
    .platform_wrapper {
      margin: 100px auto 0;
      .Twice_block_into {
        width: 100%;
        margin: 0px auto 40px;
        .line_container_left {
          .line {
            display: none;
          }
          .cross {
            @include utils.cross-mobile();
          }
        }
        span {
          @include utils.font-size-mobile(headline);
          margin: 0 15px;
        }
        .line_container_right {
          .line {
            display: none;
          }
          .cross {
            @include utils.cross-mobile();
          }
        }
      }
      .platform_container {
        .platform_leftArea {
          display: none;
        }
        .platform_mainArea {
          flex-direction: column;
          .platform_mainArea_left {
            margin: 0 0 10px;
          }
          .platform_mainArea_main {
            width: 0px;
          }
          .platform_mainArea_right {
            width: 98%;
            .switch_img,
            .switch_img_en {
              margin: 0;
              height: 150px;
              background-repeat: no-repeat;
              background-position: center center;
            }
          }
        }
        .platform_rightArea {
          display: none;
        }
      }
    }
    .twice_block_two {
      .Twice_block_into {
        width: 100%;
        margin: 0px auto 20px;
        .line_container_left {
          .line {
            display: none;
          }
          .cross {
            @include utils.cross-mobile();
          }
        }
        span {
          @include utils.font-size-mobile(headline);
          margin: 0 15px;
        }
        .line_container_right {
          .line {
            display: none;
          }
          .cross {
            @include utils.cross-mobile();
          }
        }
      }
      .vidoe_container {
        padding: 0 15px;
        width: 100%;
        max-width: 650px;
      }
      .imageContainer {
        padding: 0 15px;
        max-width: 700px;
        margin: 0 auto;
      }
      .Video_textArea_two {
        max-width: 650px;
        width: 100%;
        margin: 0px auto;
        padding: 0 15px;
        height: auto;
        .Video_textArea_title {
          @include utils.font-size-mobile(title);
        }
        span {
          @include utils.font-size-mobile(small);
        }
      }
      .Twice_block_into_small_Area {
        .Twice_block_into_small {
          flex-direction: column;
          padding: 0 15px;
          margin-bottom: 60px;
          .Twice_block_into_small_left {
            margin: 0 auto 0;
            width: 100%;
            max-width: 600px;
          }
          .Twice_block_into_small_right {
            text-align: left;
            margin: 0 auto;
            width: 100%;
            max-width: 600px;
            .Twice_block_into_small_right_title {
              @include utils.font-size-mobile(title);
            }
            .Twice_block_into_small_right_text {
              @include utils.font-size-mobile(small);
              width: 100%;
              height: auto;
              font-size: 14px;
            }
          }
        }
        hr {
          display: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .twice_block_one {
    .info_wrapper {
      table {
        tbody {
          tr {
            td {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}
