@mixin line_container_left($width,$height, $cross_url,$line_url) {
  @include mid_position;
  .line {
      background-image: url($line_url);
      width: $width;
      height: $height;
      background-repeat: no-repeat;
      background-size: 100%;
  }
  .cross {
      background-image: url($cross_url);
      width: 20px;
      height: 20px;
      margin: 0 50px 0 20px;
      background-size: 100%;
  }  
}
@mixin line_container_right($width,$height, $cross_url,$line_url) {
  @include mid_position;
  .line {
      background-image: url($line_url);
      width: $width;
      height: $height;
      background-repeat: no-repeat;
      background-size: 100%;
  }
  .cross {
      background-image: url($cross_url);
      width: 20px;
      height: 20px;
      margin: 0 20px 0 50px;
      background-size: 100%;
  }  
}

@mixin mid_position {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin button($top, $beside) {
  a {
    text-decoration: none;
    .button {
        cursor: pointer;
        margin: $top $beside;
        background-image: url("pictures/object/button.png");
        background-repeat: no-repeat;
        width: 250px;
        height: 50px;
        font-size: 24px;
        font-weight: bold;
        @include mid_position;
        color:black;
    }
  }
}

@mixin snakeGame_button {
  background-image: url("pictures/object/button.png");
  background-repeat: no-repeat;
  width: 250px;
  height: 50px;
  font-size: 24px;
  font-weight: bold;
  background-color: transparent;
  border: 0;
  @include mid_position;
}

@mixin arrow ($color) {
    width: 100px;
    margin: 0 auto;
    cursor: pointer;
    color: $color;
    svg {
      width: 37px;
      height: 43px;
    }
}

@mixin reverse ($color){
  color: $color;
  cursor: pointer;
  flex-direction: column;
  text-decoration: none;
  svg {
    width: 37px;
    height: 43px;
    transform: rotate(0.5turn);
  }
}


@mixin font-size($number) {
  @if ($number == headline) {
    font-size: 40px;
    font-weight: 900;
  } @else if ($number == title) {
    font-size: 29px;
    font-weight: 500;
  }@else if ($number == small) {
    font-size: 18px;
  }
}

@mixin font-size-mobile($number) {
  @if ($number == headline) {
    font-size: 23px;
  } @else if ($number == title) {
    font-size: 16px;
  }@else if ($number == small) {
    font-size: 14px;
  }
}

@mixin cross-mobile {
  margin: 0 20px;
  width: 10px;
  height: 10px;
}

@mixin shop_picture ($url) {
  background-image: url($url);
  background-repeat: no-repeat;
  width: 100%;
  height: 325px;
}

@mixin title-arrow {
  margin: 0px auto 55px;  
}

@mixin title-text {
  padding: 5px 0;
}

@mixin padding-bottom-mobile {
  padding-bottom: 110px;
}

@mixin block_title {
  margin-top: 110px;
}
@mixin padding-bottom {
  padding-bottom: 110px;
}